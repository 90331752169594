@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  
  .is-loading:not(:global)::after {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: inherit;
    border-radius: 290486px;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
    left: calc(50% - (1em / 2));
    position: absolute;
    top: calc(50% - (1em / 2));
    animation: spinner 0.6s infinite linear;
  }
  