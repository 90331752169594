.map-container {
    height: 500px;
  }
  
  .map-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
  }
  
  .progress-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @media screen and (max-width: 768px) {
    .map-container {
      height: 250px;
    }
  }

  .collection-item {
    margin-bottom: 0.5rem;
  }

  